import React from "react"
import SEO from "../components/seo"
import GridItem from "../components/grid-item"
import { BodyCopy, Standfirst, BulletPoints } from "../components/styles/textStyles"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <GridItem start="3" end="7" >
      <Standfirst>Strategic real estate advice <br/>for every circumstance.</Standfirst>
    </GridItem>
    <GridItem start="3" end="5" tabletEnd="7">
      <BodyCopy>
        <p>Over the last 40 years, Weitzman has helped its clients navigate threats and opportunities in the very best, and very worst, of times.  We are motivated to inform and guide our clients toward ideal real estate solutions.  We do not just check the box – we work to ensure that our clients achieve a deep and nuanced understanding of the factors shaping their potential.</p>
        <p>Our collaborative spirit pushes projects forward. Weitzman provides customized research, new development conceptualization and vetting, repositioning strategies, financial modeling, valuation, litigation support, and creative deal and partnership structuring.  We are an extension of our client’s team, and protectors of their best interests.</p>
      </BodyCopy>
    </GridItem>
    <GridItem start="5" end="7" tabletStart="3" tabletEnd="7" insetContent="10px">
      <BodyCopy>
        <p>Weitzman is expert in all property types, advising over 300 clients per year throughout the United States and beyond. Our work routinely includes:</p>
        <BulletPoints>
          <li>Rental and for-sale housing of all types</li>
          <li>Branded luxury condominiums</li>
          <li>Hotels and resorts</li>
          <li>Office properties and industrial/flex properties</li>
          <li>Retail destinations of all types</li>
          <li>Major urban and master-planned mixed-use developments</li>
          <li>Specialty research, lab and data center properties</li>
          <li>Transferable development rights</li>
          <li>Senior Housing, from IL to AL and CCRC</li>
          <li>Co-living, student, and affordable housing</li>
        </BulletPoints>
      </BodyCopy>
    </GridItem>
  </>
)

export default IndexPage
