import styled from "styled-components"

export const BodyCopy = styled.div`
  font-size: ${props => (props.fontSize ? props.fontSize : '20px')};
  line-height: 1.2em;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 5.333vw;
  }

  a {
    border-bottom: 1px solid #FFF;
  }

  p,
  ul {
    margin-bottom: 1em;
  }
`

export const Standfirst = styled.h2`
  font-family: 'GillSansNova-Light', sans-serif;
  font-size: 9.867vw;
  font-weight: 200;
  line-height: 1.2em;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    br {
      display: none;
    }
  }

  @media (min-width: 768px) {
    font-size: 5.273vw;
  }

  @media (min-width: 1024px) {
    font-size: 54px;
  }

  @media (min-width: 1440px) {
    font-size: ${props => (props.fontSize ? props.fontSize : '60px')};
  }
`

export const Heading = styled.h4`
  margin-bottom: 1.5em;
`

export const BulletPoints = styled.ul`
  padding-left: 1em;
`

export const Services = styled.ul`
  @media (min-width: 768px) and (max-width: 1439px) {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @media (max-width: 1439px) {
    margin-bottom: 54px;
  }

  li:nth-child(${props => props.activeService}) {
    font-family: 'GillSansNova-SemiBold', sans-serif;

    span {
      border-bottom: 1px solid #FFF;
    }
  }
`

export const Service = styled.li`
  list-style: none;
  line-height: 30px;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) and (max-width: 1439px) {
    width: 50%;
  }

  span {
    display: inline-block;
    line-height: 1.3em;
  }

  &:hover {
    cursor: pointer;

    span {
      border-bottom: 1px solid #FFF;
    }
  }
`